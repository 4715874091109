import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from '../../components/Hero';
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import ContentImg from '../../components/GSTTemp/ContentImg';
import SliderContent from '../../components/GSTTemp/SliderContent';
import Cta from '../../components/Cta';
import Package from '../../components/GSTTemp/Package';

export default function ChangeCompanyClause() {
  /* Slider */
  // const ChangeInNameSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Change In Name Clause`,
  //     content: `Apply for change in name clause online with TODAYFILINGS Experts`,
  //     image: '/imgs/business/sliders/nidhi-slider.png',
  //     alt_tag: 'Best Online Change in Object Clause in Hosur',
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Change In Name Clause`,
    buyBtnLink: `#pricing-buy`,
    price: `1999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data With Form*/
  const ChangeInAboutData = {
    header: `Change in Name Clause`,
    sub_title: `Change a company name through online`,
    content_paragraph: [
      // `The name of the company is the identity of the business
      // throughout its run or period, thus it should be carefully
      // considered before registering in such a way that the name of
      // the company may remain sustainable with the Company's growth
      // and development.`,

      // `The Companies Act governs the naming procedure for the
      // company, from the initial selection to any further
      // adjustments or alterations. This page thoroughly explains
      // certain do's and don'ts.`,

      `Company's request to change 
      its name in accordance with Section 13 of the Companies Act. 
      You should concentrate on growing your company, and the TODAYFILINGS 
      experienced team will handle all legal matters for you.`,
    ],
  };

  /* Scroll Nav Data */
  const ChangeInCompScrollId = [
    {
      id: `#guidelines`,
      heading: `Guidelines`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#restrictions`,
      heading: `Restrictions`,
    },
    {
      id: `#conditions`,
      heading: `Conditions`,
    },
  ];

  /* Img Content Component Data */
  const ChangeInCompIcData = {
    id: 'guidelines',
    mt_div: '',
    mt_img: '',
    header: 'Guidelines for naming the company',
    image: '/imgs/registration/shop/shop-reg.png',
    alt_tag: 'Naming The Company',
    Paragraph: `The Companies Act of 2013 stipulates the rules and
    conditions for naming a company, whether it is a
    private limited company, a public limited company, or
    an OPC. When selecting a name for the company, the
    corporation should consider the following factors:`,
    points: [
      {
        content: `The MCA ensures that the proposed name does not
        resemble any of the names that are currently
        registered as a trademark or corporate name.`,
        icon: true,
      },
      {
        content: `Simply combining the different words or altering the
        name to its plural form does not make it unique.`,
        icon: true,
      },
      {
        content: `Last terms of a One Person Company with OPC
        Private Limited, Private Company, and Public Company
        shall be Private Limited and Limited, respectively.`,
        icon: true,
      },
      {
        content: `MCA prohibits the use of names that are
        excessively common or broad, such as Wood Company
        Private Limited.`,
        icon: true,
      },
      {
        content: `Companies' names must begin with a person's name, such
        as Example Arun Private Limited.`,
        icon: true,
      },
    ],
  };
  /* Content Form Component Data */
  const ChangeInCompCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Procedure for company name change',
    content: ``,
    body: [
      {
        head: 'Conduct general board meeting',
        points: `The suggested name of the corporation will be
        discussed by the entity's directors. A seven-day
        notice is given to the directors in order to carry
        out the board meeting. The following are the motions
        that must be passed at a board meeting in order for
        the name change to be approved.`,
        icon: true,
      },
      {
        points: `To authorize the Director or Company Secretary to
        use ROC to determine whether or not a specific name
        is available.`,
        icon: true,
      },
      {
        points: `The Company should ensure that no two firms with the
        same or similar names are registered. To determine
        the time and location of the EGM for the approval of
        the new name, and if authorized, the change in the
        MOA must be implemented.`,
        icon: true,
      },
      {
        head: 'Step by step procedure',
        points: `Check the name's availability`,
        icon: true,
      },
      {
        points: `To receive name approval, submit an online
        application`,
        icon: true,
      },
      {
        points: `Organize an EGM or an extra-ordinary general
        meeting`,
        icon: true,
      },
      {
        points: `Filing with the ROC`,
        icon: true,
      },
      {
        points: `Central government approval`,
        icon: true,
      },
      {
        points: `New incorporation certificate`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const ChangeInCompCiData = {
    id: 'conditions',
    background: 'main-content',
    mt_div: '',
    header: `The proposed name is undesirable if it violates the
    following conditions:`,
    paragraph: `If the following conditions are not met, the applied
    name will not be acceptable.`,
    points: [
      {
        content: `Infringes on the Emblem and Name Act`,
        icon: true,
      },
      {
        content: `Infringes on a trademark`,
        icon: true,
      },
      {
        content: `Contains deceptive or unpleasant language`,
        icon: true,
      },
      {
        content: `The name chosen by the company must correspond to the
        Entity's objectives.`,
        icon: true,
      },
      {
        content: `Name of the firm shall not include words that
        refer to any constitution or legal person, such as
        LLP, HUF, Trust, and so on.`,
        icon: true,
      },
      {
        content: `If the company is registered to provide
        business-related financial services such as finance,
        chit, fund, leasing, and so on, such operations must
        be mentioned in the firm's name.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/tax/tax-img-sliders.png',
    alt_tag: 'Required Documents for GST Return Filling Registration',
  };
  /* Slider */
  const imgSlider = [
    '/imgs/business/form-filing.png',
    '/imgs/business/checklist.png',
    '/imgs/business/compreg.png',
  ];

  /*  Slider Content Component Data */
  const ChangeInNameScData = {
    id: 'restrictions',
    background: [],
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: 'Restrictions on Changing the Company Name',
    paragraph: `The following businesses are particularly prohibited
      from altering their name under Rule 29 of the 2014
      Companies Rules:`,
    content: [
      {
        points: `The business responsible for not paying the matured
          deposit.`,
        icon: true,
      },
      {
        points: `Business that hasn't repaid its debentures that have
          matured.`,
        icon: true,
      },
      {
        points: `Your business that failed to timely file its annual return.`,
        icon: true,
      },
      {
        points: `The business that has failed to pay interest on
          deposits or debentures or has defaulted on doing so.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };
  var FAQ_data = [
    {
      header:
        'Is there a form that can enforce name consolidation similar to the SPICe form?',
      body: [
        {
          content: `No, the name must be approved in the event that an existing
              company changes its name by RUN alone.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What are the impacts of the name change on the Company? ',
      body: [
        {
          content: `Changing of existing name doesn't have any impact on the legal identity of the Company. 
          Changing the name is not creating a new entity. The changed name doesn't:`,
          icon: false,
        },
        {
          content: `Affect the existing rights of Companies`,
          icon: true,
        },
        {
          content: `Affects the legal proceedings against the Company pending in the old name`,
          icon: true,
        },
        {
          content: `Affects the legal proceeding initiated by the Company in the old name.`,
          icon: true,
        },
      ],
    },

    {
      header:
        'What if the reserved company name were to expire for whatever reason?',
      body: [
        {
          content: `Re-registration of the name is required. It is also advised to
              include the previous name permission letter that has since
              expired.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Can the name approval letter be confirmed after the deadline for submitting a resolution to the ROC has passed?',
      body: [
        {
          content: `The name will remain valid after ROC approval for 20 days, during
              which time a special resolution must be voted and filed;
              otherwise, the approval letter will become null and invalid.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What may the justifications be for a name change at a general meeting?',
      body: [
        {
          content: `The Company typically changes its name due to the following
              factors:`,
          icon: false,
        },
        {
          content: `If the Company modifies its commercial operations.`,
          icon: true,
        },
        {
          content: `A corporation must be
              willing to change its current name in order to better reflect the
              brands it deals with.`,
          icon: true,
        },
        {
          content: `Occasionally, if the government directs a name change.`,
          icon: true,
        },
      ],
    },
    {
      header:
        'How long does it generally take to change of name clause in India?',
      body: [
        {
          content: `It usually takes around 3-4 working days in total to change the name fo clause
           on the Companies House register in India.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'When can Central Government issue order for Change in Name of Company?',
      body: [
        {
          content: `When Central Government is in opinion that the name is identical with or
           too nearly resembles the name of:`,
          icon: false,
        },
        {
          content: `Existing Company;`,
          icon: true,
        },
        {
          content: `on an application by a registered proprietor of a trade mark that the 
          name is identical with or too nearly resembles to his registered trade mark.`,
          icon: true,
        },
      ],
    },
    {
      header:
        'Which resolution is required to be passed to change the company name in India?',
      body: [
        {
          content: `To change the company name in India, a special resolution of members is required to be passed 
          in an extraordinary general meeting. The most commonly used way to change the company name is by passing 
          a special resolution of the members. A special resolution is simply a formal decision that should be agreed 
          on by at least 75% of the members' votes. A special resolution can be passed in a general meeting or writing.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is there any other form through which the name can be applied to consolidate like in the SPICe form?',
      body: [
        {
          content: `No, the name of the company has to be reserved in case of the change company name of an existing Company 
          through RUN form only.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Does the stamp duty is needed to be paid again on the change company name clause?',
      body: [
        {
          content: `No, the stamp duty is generally paid on the allotment of shares and further, the alteration is not the adoption 
          of a new set of memorandums anyhow.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Change in Name Clause'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ChangeInNameSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ChangeInAboutData} />

          <Counter />

          <ScrollNav scroll_data={ChangeInCompScrollId} />
          <ImgContent item={ChangeInCompIcData} />
          <ContentForm CFSection_data={ChangeInCompCfData} />

          <SliderContent ScSection_data={ChangeInNameScData} />
          <ContentImg CISection_data={ChangeInCompCiData} />
          <Cta />

          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
